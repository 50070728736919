import { css } from 'lit';

/*
This file is reserved for any custom css that developers want to add to
customize their theme. Simply add your css to this file and yarn build.
*/

export default css`
  .read-mode {
    margin-top: 2rem;
  }

  .main-content > *,
  .full-width {
    margin: 0 auto;
    max-width: var(--full-width, 1400px);
  }

  .expanded-req-resp-container {
    background-color: #e7ebf1;
    border: 1px solid #c5c9cb;
    box-shadow: 0 10px 20px #252f341a;
    border-radius: 0.5rem;
    margin: 2rem 1rem;
    padding: 0 2rem 2rem 2rem;
    position: relative;
    overflow: hidden;
  }

  api-request[allow-try='true'] {
    display: block;
    margin-top: 3rem;
  }

  api-request[allow-try='true']::before {
    background-color: var(--primary-color, #ff5900);
    color: var(--primary-color-invert, #ffffff);
    content: 'Try me';
    display: block;
    font-size: 0.825rem;
    font-weight: 600;
    left: 0;
    padding: 0.2em 2em;
    position: absolute;
    top: 0;
    transform: translate(-29.29%, -100%) rotate(-45deg);
    transform-origin: bottom right;
  }

  @media only screen and (min-width: 768px) {
    .expanded-req-resp-container {
      margin: 2rem -0.5rem;
    }
  }

  @media only screen and (min-width: 1024px) {
    .expanded-req-resp-container {
      margin: 2rem -2rem;
    }
  }

  #auth .sub-title {
    font-size: calc(var(--font-size-small) + 18px);
    font-weight: 600;
  }

  .nav-method.as-colored-text {
    display: inline-block;
    margin-right: 1em;
    text-align: right;
    width: 4em;
  }

  @media print {
    #auth,
    div.m-markdown + div /* Authentication div */,
    .expanded-req-resp-container {
      display: none;
    }
  }
`;
